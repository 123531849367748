import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    listaUsuarios: [],
    listaPermissoes: [],

  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },

    alteraListaUsuarios(state, data) {
      state.listaUsuarios = data
    },
    alteraListaPermissoes(state, data) {
      state.listaPermissoes = data
    },

  },
  actions: {

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/roles', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchUsuarios({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users?perPage=10000')
          .then(response => {
            commit('alteraListaUsuarios', response.data)

            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },
    listaPermissoes({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', { params: queryParams })
          .then(response => {
            resolve(response.data)

            commit('alteraLoading', false)
            commit('alteraListaPermissoes', response.data.data.map(item => item.name))
          })
          .catch(error => console.log(error))
      })
    },

    fetchPermissoes({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', { params: queryParams })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roles/${item.name}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roles', itemData)
          .then(response => resolve((response)))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios.put(`roles/${itemData.name}`, itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roles/${itemData}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
